import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
const columns = [
  {
    field: "reportId",
    headerName: "Report ID",
    width: 250,
  },
  { field: "lotUID", headerName: "Lot UID", width: 250 },
  { field: "code", headerName: "Code", width: 200 },
  { field: "utid", headerName: "UTID", width: 160 },
  { field: "isPrinted", headerName: "Tiskano", width: 100, align: "center" },
  { field: "activated", headerName: "Aktivirano", width: 100, align: "center" },
  { field: "status", headerName: "Uništeno", width: 100, align: "center" },
  { field: "createdAt", headerName: "Created At", width: 200 },
];

export default function TablePaginated(props) {
  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={props.data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
  );
}
