import axios from "axios";
import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { store } from "../state/store";

export default function Login({ ...props }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { state, dispatch } = useContext(store);

  const login = async () => {
    axios
      .post(`${process.env.REACT_APP_URL}/auth/login`, {
        username: username,
        password: password,
      })
      .then(async (response) => {
        console.log("Response", response);

        dispatch({
          type: "SET_DATA",
          payload: {
            isAuthenticated: response.data.user == undefined ? false : true,
            user: response.data.user,
            accessToken: response.data.access_token,
          },
        });
        const loggedUser = {
          user: response.data.user,
          accessToken: response.data.access_token,
          isAuthenticated: response.data.user == undefined ? false : true,
        };

        localStorage.setItem("user", JSON.stringify(loggedUser));
        loggedUser.isAuthenticated
          ? props.history.replace("/")
          : props.history.replace("/login");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        props.history.replace("/login");
      });
    console.log(state, "===state");
  };

  return (
    <>
      <div className="login-container">
        <Container>
          <Row>
            <Col lg={6} className="login-img"></Col>
            <Col lg={6} className="login-details">
              <div className="login-form">
                <div className="login-inputs">
                  <h2>
                    <strong>Login</strong>
                  </h2>
                  <div className="login-form-fields">
                    <label>Username</label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="email"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <label>Password</label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="btn btn-lg btn-outline-primary rounded mt-4 px-5"
                    onClick={() => login()}
                  >
                    Login
                  </div>
                  {/* <h5 style={{textAlign: "right", color: "#5CC8BD", fontSize: "20px"}}>Pozabljeno geslo?</h5> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
