import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import { StateProvider } from "./state/store";

if (process.env.NODE_ENV === "development") {
} else {
  console.log = function () {};
}

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <Router />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
