import { Box, Button, Grid } from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
const { convertArrayToCSV } = require("convert-array-to-csv");

export default function CheckPrint() {
  const { state } = useLocation();
  const [printedKits, setPrintedKits] = React.useState([]);
  const [unfinishedKits, setUnfinishedKits] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const navigate = useNavigate();

  const header = [
    "Id-Uputnice",
    "LotID",
    "AktivacijskiURL",
    "Kod",
    "UTID",
    "Datum",
  ];

  useEffect(() => {
    setUnfinishedKits(state.kits);
  }, []);

  const handleChange = (e, kit) => {
    console.log("handleChange: ", e.target.value);
    const newlyprintedKits = [...printedKits];

    if (e.target.checked) {
      newlyprintedKits.push({
        id: kit.id,
        kitType: kit.kitType,
        lotUID: kit.lotUID,
        isPrinted: e.target.checked,
      });

      const filtered = unfinishedKits.filter(function (item) {
        return item.id !== kit.id;
      });

      setUnfinishedKits(filtered);

      console.log(filtered);
      setPrintedKits(newlyprintedKits);

      console.log(unfinishedKits.length);
      if (unfinishedKits.length == 1) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
    }
  };

  console.log(state);

  const generateCSV = () => {
    const filteredArray = [];

    state.kits.map((kit) => {
      filteredArray.push([
        kit.reportId,
        kit.lotUID,
        kit.activationUrl,
        kit.code,
        kit.utid,
        kit.createdAt,
      ]);
    });

    console.log(filteredArray);
    const csvFromArrayOfArrays = convertArrayToCSV(filteredArray, {
      header,
      separator: ",",
    });

    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();

    const fileName = `${dd}_${mm}_${yy}-${state.kits.length}-kits.csv`;

    fileDownload(csvFromArrayOfArrays, fileName);
  };

  const confirmOnApi = () => {
    const toSend = [];
    printedKits.map((kit) => {
      toSend.push({
        id: kit.id,
        isPrinted: kit.isPrinted ? "true" : "false",
      });
    });

    console.log(toSend);
    axios
      .put(`${process.env.REACT_APP_URL}/kits/printed`, {
        kits: toSend,
      })
      .then((res) => {
        navigate("/");
      })
      .catch((err) => {});
  };

  return (
    <Box>
      <Grid container spacing={2} style={{ padding: "2% 5%" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignContent: "center",
            justifyItems: "center",
            alignSelf: "center",
            padding: "0 2%",
          }}
        >
          <img src={logo} style={{ width: "20%", height: "auto" }} />
        </Grid>
        <Grid item sm={10}>
          <h1>Freshly Generated Kits</h1>
          <p>
            These kits are freshly generated and unpritned, when you finish
            printing and labeling them check the boxes to confirm that this
            batch is done!
          </p>
        </Grid>
        <Grid
          item
          sm={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3f51b5",
              width: "80%",
              padding: "1%",
              fontSize: "1rem",
            }}
            onClick={() => {
              generateCSV();
            }}
            size="large"
          >
            Generate CSV
          </Button>
        </Grid>
        <Grid item sm={12}>
          <hr />
        </Grid>

        <Grid item sm={12}>
          <h2>Unprinted Kits</h2>

          <table className="table">
            <thead>
              <tr>
                <th>Kit ID</th>
                <th>Kit Type</th>
                <th>Kit LOT ID</th>
                <th>Status</th>
                <td align="center">Printed And Labeled</td>
              </tr>
            </thead>
            <tbody>
              {unfinishedKits.map((kit) => {
                return (
                  <tr key={kit.id}>
                    <td>{kit.id}</td>
                    <td>{kit.kitType}</td>
                    <td>{kit.lotUID}</td>
                    <td>{kit.isPrinted ? "Printed" : "Not printed"}</td>
                    <td align="center">
                      <input
                        type="checkbox"
                        onChange={(e) => handleChange(e, kit)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
        <Grid item sm={12}>
          <br />
          <br />
          <h2>Printed Kits</h2>

          <table className="table">
            <thead>
              <tr>
                <th>Kit ID</th>
                <th>Kit Type</th>
                <th>Kit LOT ID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {printedKits.map((kit) => {
                return (
                  <tr key={kit.id}>
                    <td>{kit.id}</td>
                    <td>{kit.kitType}</td>
                    <td>{kit.lotUID}</td>
                    <td>{kit.isPrinted ? "Printed" : "Not printed"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
        <Grid item sm={4}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#C4385B",
              width: "80%",
              padding: "1.5%",
              fontSize: "1.3rem",
            }}
            onClick={() => {
              confirmOnApi();
            }}
            disabled={isDisabled}
            size="large"
          >
            {isDisabled ? "Preveri seznam" : "Potrdi in nadaljuj"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
