import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import TablePaginated from "../../components/table";
const { convertArrayToCSV } = require("convert-array-to-csv");
const converter = require("convert-array-to-csv");

const header = ["number", "first", "last", "handle"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function App() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [kits, setKits] = React.useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/kits?lotUID=&type=&limiter=&offset=&isPrinted=true`
      )
      .then((res) => {
        console.log("res", res);
        setKits(res.data);
      })
      .catch((err) => {});
  }, []);

  const handleData = async (type) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/kits?lotUID=&type=${type}&limiter=&offset=&isPrinted=true`
      )
      .then((res) => {
        console.log("res", res);
        setKits(res.data);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{}}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab
              label="Vsi"
              {...a11yProps(0)}
              onClick={() => {
                handleData("");
              }}
            />
            <Tab
              label="STI Basic"
              {...a11yProps(1)}
              onClick={() => {
                handleData("01-STI-1");
              }}
            />
            <Tab
              label="STI Simple"
              {...a11yProps(2)}
              onClick={() => {
                handleData("01-STI-2");
              }}
            />
            <Tab
              label="STI Standard"
              {...a11yProps(3)}
              onClick={() => {
                handleData("01-STI-3");
              }}
            />
            <Tab
              label="STI Basic Pro"
              {...a11yProps(4)}
              onClick={() => {
                handleData("01-STI-1-PRO");
              }}
            />
            <Tab
              label="STI Simple Pro"
              {...a11yProps(5)}
              onClick={() => {
                handleData("02-STI-2-PRO");
              }}
            />
            <Tab
              label="STI Standard Pro"
              {...a11yProps(6)}
              onClick={() => {
                handleData("01-STI-3-PRO");
              }}
            />
            <Tab
              label="ULCUS"
              {...a11yProps(7)}
              onClick={() => {
                handleData("04-STI-4");
              }}
            />
            <Tab
              label="Vaginitis"
              {...a11yProps(8)}
              onClick={() => {
                handleData("04-VAGINITIS");
              }}
            />
            <Tab
              label="HPV 14"
              {...a11yProps(9)}
              onClick={() => {
                handleData("04-HPV-14");
              }}
            />
            <Tab
              label="HPV 24"
              {...a11yProps(10)}
              onClick={() => {
                handleData("04-HPV-24");
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <TablePaginated data={kits} />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <TablePaginated data={kits} />
        </TabPanel>
      </Box>
    </>
  );
}
