import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  Modal,
  NativeSelect,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import "./App.css";
import logo from "./assets/images/logo.png";
import PrintedKits from "./pages/kits/printed";
import KitsReport from "./pages/kits/report";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function App() {
  const [value, setValue] = React.useState(0);
  const [kitAmmount, setKitAmmount] = React.useState("");
  const [kitType, setKitType] = React.useState("");
  const [token, setToken] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [examination, setExamination] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const client_id = process.env.REACT_APP_EMBL_API_CLIENT_ID;
    const client_secret = process.env.REACT_APP_EMBL_API_CLIENT_SECRET;

    const auth_key = client_id + ":" + client_secret;
    const encoded_Auth_Key = btoa(auth_key);
    console.log("encoded_Auth_Key::", encoded_Auth_Key);
    axios
      .post(
        `${process.env.REACT_APP_EMBL_API_TOKEN_URL}`,
        {},
        {
          headers: {
            Authorization: `Basic ${encoded_Auth_Key}`,
          },
          params: {
            grant_type: "client_credentials",
          },
        }
      )

      .then((response) => {
        setToken(response.data.access_token);
        console.log("Token Req Radi:", response);
      })
      .catch((error) => {
        console.log("Greska Post Token ", error);
      });
  }, []);
  // let ex = examination;
  let kitName = kitType;
  let ex;
        

  switch (kitName) {
    case "01-STI-1":
      kitName = "STI Basic";
      ex = [{
        "examination": {
            "id": "N0760",
            "description": "KLAMIDIJE - SEROLOŠKE PREISKAVE",
            "shortDescription": "KLAMIDIJE protitelesa",
            "sampleEntryConfiguration": {},
        },
       
    }]
      break;
    case "01-STI-2":
      kitName = "STI Simple";

      ex = [{
        "examination": {
            "id": "N0760",
            "description": "KLAMIDIJE - SEROLOŠKE PREISKAVE",
            "shortDescription": "KLAMIDIJE protitelesa",
            "sampleEntryConfiguration": {},
        },
       
    }, {
        "examination": {
            "id": "N0780",
            "description": "MIKOPLAZMA PNEUMONIAE- SEROLOŠKE PREISKAVE",
            "shortDescription": "MIKOPLAZMA ELISA",
            "sampleEntryConfiguration": {},
        },
       
    }]

      break;
    case "01-STI-3":
      kitName = "STI Standard";
      ex = [{
        "examination": {
            "id": "N0700",
            "description": "SIFILIS - OSNOVNE PREISKAVE",
        },
       
    }, {
        "examination": {
            "id": "N0760",
            "description": "KLAMIDIJE - SEROLOŠKE PREISKAVE",
            "shortDescription": "KLAMIDIJE protitelesa",
            "sampleEntryConfiguration": {},
        },
       
    }, {
        "examination": {
            "id": "N0780",
            "description": "MIKOPLAZMA PNEUMONIAE- SEROLOŠKE PREISKAVE",
            "shortDescription": "MIKOPLAZMA ELISA",
            "sampleEntryConfiguration": {},
            "referralEntryConfiguration": {
                "recentExaminationRepeatedWarning": null
            }
        },
      
    }]


      break;
    case "01-STI-1-PRO":
      kitName = "STI Basic Pro";
      break;
    case "02-STI-2-PRO":
      kitName = "STI Simple Pro";
      break;
    case "01-STI-3-PRO":
      kitName = "STI Standard Pro";
      break;
    case "04-STI-4":
      kitName = "ULCUS";
      break;
    case "04-VAGINITIS":
      kitName = "Vaginitis";
      break;
    case "04-HPV-14":
      kitName = "HPV 14";
      break;
    case "04-HPV-24":
      kitName = "HPV 24";
      break;

    default:
      kitName = "No value found";
  }

  const data = {

    "state": "draft",
    "sampleCollection": "onsite",
  
    "authorInstitution": {
        "id": "X00292",
        "name": "NYDMIKRO"
    },
    "authorOrgUnit": {
        "id": "NYDMIKRO",
        "description": "NYD d.o.o",
        "address": {
            "street": "Dolenjska cesta 343D",
            "city": "Škofljica",
            "postalCode": "1291",
            "countryCode": "705"
        },
        "phone": "+38651426911"
    },
    "author": {
      "id": "06578",
      "prefix": "prim.asist.mag.",
      "firstName": "Branko",
      "lastName": "Šibanc",
      "suffix": "dr.med."
    },
    "patient": {
        "id": "646225",
        "firstName": "Klemen",
        "lastName": "Rupnik",
        "gender": {
            "id": "male",
            "description": "MOŠKI"
        },
        "birthDate": "1977-01-26",
        "addresses": [{
            "street": "",
            "city": "Srednje Bitnje",
            "postalCode": "4209",
            "countryCode": "705",
            "type": "permanent",
            "contact": true
        }, {
            "street": "",
            "city": "Začasne Bitnje",
            "postalCode": "4209",
            "countryCode": "705",
            "type": "temporary"
        }],
        "mobilePhone": "000000",
        "phone": "000000"
    },
    "performer": {
      "id": "2002",
      "name": "NLZOH Novo mesto"
    },
    "referral": {
        "id": "SNY",
        "description": "SEROLOŠKE PREISKAVE",
        "validFrom": null,
        "color": "#FFFF00",
        "info": "",
        "screening": null,
        "priceVisible": null,
        "allExaminationsAllowed": null,
        "details": {
            "validFrom": null,
            "color": "#FFFF00",
            "transplantation": null,
            "cysticFibrosis": null,
            "priority": null,
            "priceVisible": null,
            "screening": null,
            "antibiotics": null,
            "diagnoses": null,
            "recentExaminationRepeatedWarning": null,
            "patientPhone": null,
            "payerType": null,
            "testIndication": null,
            "patientNotificationType": null,
            "patientNotificationTypeId": null,
            "contactWithConfirmedCase": null,
            "symptoms": null,
            "collectionLocation": null,
            "testType": null,
            "travelAbroad": null,
            "diseaseAppearance": null,
            "patientIdDocumentNumber": null,
            "patientEmail": null,
            "allExaminationsAllowed": null,
            "info": ""
        }
    },
  
    "hpvTriageResultDateUnknown": false,
    "samples": [{
        "sample": {
            "id": "110",
            "description": "Urin prvi curek",
            "collectedBy": "Samoodvzem",
            "collectionTime": "2022-08-31",
        },
    
     

        "examinations": ex
        
        
    }]
};


  const genereteCode = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const genereteUTID = (length) => {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const fillArray = (number) => {
    var array = [];
    for (var i = 0; number > i; i++) {
      array.push("1");
    }
    setKitAmmount(array);
    console.log(array);
  };

  const generateKits = async () => {
    const generatedKits = [];
    const number = kitAmmount;

    //   for (let i = 0; i < number.length; i++) {

    //     console.log("Broj Inputa",i++, number);

    // await  axios.post("http://193.23.137.169:8054/api/embl/observation-requests",  data,{
    //       headers: {
    //         "Content-Type": "application/json",
    //          'Authorization' : `Bearer ${token}`,
    //       },
    //     }).then((response)=>{
    //       console.log(response);
    //     });
    //   }

    kitAmmount.map((item, index) => {
      axios
        .post(
          `${process.env.REACT_APP_EMBL_API_URL}/api/embl/observation-requests`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Lab:", response.data);
          const code = genereteCode(6);
          const utid = genereteUTID(6);

          //For Kit Name

        

          //Date Format
          var d = new Date();
          var mm = d.getMonth() + 1;
          var dd = d.getDate();
          var yy = d.getFullYear();

          var myDateString = dd + "-" + mm + "-" + yy;
          // console.log("New Date:",myDateString);
          const lotuid = `NYD-${myDateString}-${utid}-${kitAmmount.length}`;
          console.log("Kit Type:", kitType);
          console.log("REport ID:", response.data.id);
        const  nyddata = {
            reportId: response.data.id,
            utid: `${kitType}-${utid}`,
            code: code,
            pacientId: "NYD-0122344",
            isPrinted: false,
            kitType: kitType,
            qrcode: response.data.id,
            barcode: response.data.id,
            lotUID: lotuid,
            pacient: {
              first_name: "NA",
              last_name: "NA",
              email: "na@email.com",
            },

            questions: {
              "Tested on covida": "no",
              "Tested on diarerhaaa": "yes",
            },
            medicalReport: {
              "Have you done its?": "Many times",
            },
            doctorOppinion: {
              oppinion: "NA",
            },
            kitDetails: {
              KitName: kitName,
              "Komplet-1": {
                UriSponge: "1",
                ENat: "0",
                FaecalSwab: "0",
              },
              TestDetails: ex
            
            },
          };

          axios
            .post(
              `${process.env.REACT_APP_URL}/kits`,
              nyddata,
              {
                headers: {
                  "Content-Type": "application/json",
                  "x-api-key":
                    "1ab2c3d4e5f61ab2c3d4e5f6AC415797d03c4946de77220174266c1753",
                },
              }
            )
            .then((response) => {
              generatedKits.push(response.data);
              console.log("API_ID:", response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        });
    });
  };

  return (
    <>
      <Grid container spacing={2} style={{ padding: "2% 5%" }}>
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignContent: "center",
            justifyItems: "center",
            alignSelf: "center",
            padding: "0 2%",
          }}
        >
          <img src={logo} style={{ width: "20%", height: "auto" }} />
        </Grid>

        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignSelf: "center",
            padding: "0 2%",
          }}
        >
          <Button variant="contained" onClick={handleOpen}>
            Generiši Kite
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%", padding: "none!important" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Generisani Kitovi" {...a11yProps(0)} />
                <Tab label="Neisprintani Kitovi" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <PrintedKits />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <KitsReport />
            </TabPanel>
          </Box>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              variant="h3"
              textAlign={"center"}
              fontSize={"36px"}
              id="modal-modal-title"
            >
              Kreiraj set kitova
            </Typography>
            <Typography
              variant="h6"
              textAlign={"center"}
              margin={"5% 0"}
              fontSize={"18px"}
              id="modal-modal-description"
            >
              Kreiramo kitove tako sto upisemo broj kitova za generaciju i
              izaberemu vrstu kita koja se treba generesati!
            </Typography>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <FormControl style={{ width: "75%" }}>
                <Typography variant="h5" htmlFor="name-simple">
                  <bold>Broj kitova:</bold>
                </Typography>

                <Input
                  type="number"
                  style={{ margin: "2% 0" }}
                  placeholder="Unesite broj kita"
                  onChange={(e) => fillArray(e.target.value)}
                />

                <Typography variant="h5" htmlFor="name-simple">
                  <bold>Vrsta kita:</bold>
                </Typography>

                <NativeSelect
                  displayEmpty
                  style={{ margin: "2% 0" }}
                  defaultValue={kitType}
                  inputProps={{
                    name: "types",
                    id: "uncontrolled-native",
                  }}
                  onChange={(e) => setKitType(e.target.value)}
                >
                  <option value="">Izberite vrsto kita</option>
                  <option value="01-STI-1">STI Basic</option>
                  <option value="01-STI-2">STI Simple</option>
                  <option value="01-STI-3">STI Standard</option>
                  <option value="01-STI-1-PRO">STI Basic Pro</option>
                  <option value="02-STI-2-PRO">STI Simple Pro</option>
                  <option value="01-STI-3-PRO">STI Standard Pro</option>
                  <option value="04-STI-4">ULCUS</option>
                  <option value="04-VAGINITIS">Vaginitis</option>
                  <option value="04-HPV-14">HPV 14</option>
                  <option value="04-HPV-24">HPV 24</option>
                </NativeSelect>
              </FormControl>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  margin: "4% 0",
                  backgroundColor: "#C4385B",
                  width: "80%",
                  padding: "1.5%",
                  fontSize: "1.3rem",
                }}
                onClick={generateKits}
                size="large"
              >
                Generiši
              </Button>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}