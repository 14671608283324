import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import CheckPrint from "./pages/kits/checkPrint";
import PrintedKits from "./pages/kits/printed";
import KitsReport from "./pages/kits/report";
import Login from "./pages/login";
import { store } from "./state/store";
export default function Router() {
  const { state, dispatch } = useContext(store);

  console.log("Router state: ", state);
  if (state.data.isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="printed" element={<PrintedKits />} />
          <Route path="unfinished" element={<KitsReport />} />
          <Route path="checkPrint" element={<CheckPrint />} />
        </Routes>
      </BrowserRouter>
    );
  } else {
    return <Login />;
  }
}
